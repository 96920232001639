export enum Experiment {
  EnableSectionsTabInMobileAS = 'specs.membersAboutOOI.enableSectionsMobileAS',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorProfilePage',
  UseWarmupData = 'specs.membersAboutOOI.useWarmupData',
  MobilePostBorderWidth = 'specs.membersAboutOOI.mobileBorderWidth',
  HeaderSettings = 'specs.membersArea.aboutHeaderSettings',
  EnablePostCounter = 'specs.membersAboutOOI.postCounterInTitle',
  RenderSEOTags = 'specs.membersAboutOOI.RenderSEOTags',
  PostCardPadding = 'specs.membersAboutOOI.postCardSidePadding',
  EnableGeneralSettings = 'specs.membersArea.generalSettings',
  EnableGeneralSettingsViewer = 'specs.membersArea.generalSettingsViewer',
  EnableCornerRadiusSettings = 'specs.membersArea.CornerRadiusSetting',
  EnableRceNext = 'specs.membersAboutOOI.enableRceNext',
}
